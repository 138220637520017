@import 'utils/reset';
@import "utils/common";

* {
  box-sizing: border-box;
}
html, body, #root, .page {
  height: 100%;
  //height: 100vh;
}
ul, ol {
  @include reset_list;
}
.color-red {
  color: $color-orange !important;
}
.cols {
  display: flex;
}
.cols.cols--plan {
  margin-bottom: 12px;
}
.cols.cols--result {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}
.cols.cols--two-buttons {
  border-radius: 16px;
  box-shadow: 4px 4px 12px 1px rgba(35, 31, 32, .25);
  margin: 8px;

  .cols__col.cols__col--half {
    padding: 4px;
    min-width: 180px;
  }
}
.cols__col {
  flex: auto;
}
.cols__col.cols__col--half {
  flex: 1 1 50%;
}
.cols__col.cols__col--plan-left {
  flex: 0 0;
  align-items: center;
  display: flex;
  flex-basis: 64px;
}
.cols__col.cols__col--ng-data-left {
  flex: 0 0;
  align-items: center;
  display: flex;
  flex-basis: 110px;
}
.cols__col.cols__col--plan-right {
  flex: auto;
}
.cols__col.cols__col--result-left {
  flex: 0 0;
  align-items: center;
  display: flex;
  flex-basis: 54px;
}
.cols__col.cols__col--result-right {
  flex: auto;
  @include text-body;
}
.page {

}
.page__structure-body {
  flex: auto;
  flex-basis: 100%;
  //overflow: auto;
}
.page__structure-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.pane {
  display: none;
}
.pane.pane--is-open {
  display: block;
}
.tg-spinner {
  display: inline-block;
  font-size: 4px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  @-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2), 2.5em 0em 0 0em rgba(225, 103, 16, 0.2), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.2), 0em 2.5em 0 0em rgba(225, 103, 16, 0.2), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.2), -2.6em 0em 0 0em rgba(225, 103, 16, 0.5), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(225, 103, 16, 0.2), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.2), 0em 2.5em 0 0em rgba(225, 103, 16, 0.2), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.2), -2.6em 0em 0 0em rgba(225, 103, 16, 0.2), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.5), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.2), 0em 2.5em 0 0em rgba(225, 103, 16, 0.2), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.2), -2.6em 0em 0 0em rgba(225, 103, 16, 0.2), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.2), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.5), 2.5em 0em 0 0em rgba(225, 103, 16, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(225, 103, 16, 0.2), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.2), -2.6em 0em 0 0em rgba(225, 103, 16, 0.2), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.2), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2), 2.5em 0em 0 0em rgba(225, 103, 16, 0.5), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.2), -2.6em 0em 0 0em rgba(225, 103, 16, 0.2), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.2), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2), 2.5em 0em 0 0em rgba(225, 103, 16, 0.2), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.5), 0em 2.5em 0 0em rgba(225, 103, 16, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(225, 103, 16, 0.2), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.2), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2), 2.5em 0em 0 0em rgba(225, 103, 16, 0.2), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.2), 0em 2.5em 0 0em rgba(225, 103, 16, 0.5), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.2), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2), 2.5em 0em 0 0em rgba(225, 103, 16, 0.2), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.2), 0em 2.5em 0 0em rgba(225, 103, 16, 0.2), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.5), -2.6em 0em 0 0em rgba(225, 103, 16, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2), 2.5em 0em 0 0em rgba(225, 103, 16, 0.2), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.2), 0em 2.5em 0 0em rgba(225, 103, 16, 0.2), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.2), -2.6em 0em 0 0em rgba(225, 103, 16, 0.5), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(225, 103, 16, 0.2), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.2), 0em 2.5em 0 0em rgba(225, 103, 16, 0.2), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.2), -2.6em 0em 0 0em rgba(225, 103, 16, 0.2), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.5), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.2), 0em 2.5em 0 0em rgba(225, 103, 16, 0.2), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.2), -2.6em 0em 0 0em rgba(225, 103, 16, 0.2), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.2), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.5), 2.5em 0em 0 0em rgba(225, 103, 16, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(225, 103, 16, 0.2), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.2), -2.6em 0em 0 0em rgba(225, 103, 16, 0.2), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.2), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2), 2.5em 0em 0 0em rgba(225, 103, 16, 0.5), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.2), -2.6em 0em 0 0em rgba(225, 103, 16, 0.2), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.2), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2), 2.5em 0em 0 0em rgba(225, 103, 16, 0.2), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.5), 0em 2.5em 0 0em rgba(225, 103, 16, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(225, 103, 16, 0.2), -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.2), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2), 2.5em 0em 0 0em rgba(225, 103, 16, 0.2), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.2), 0em 2.5em 0 0em rgba(225, 103, 16, 0.5), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(225, 103, 16, 0.2), 1.8em -1.8em 0 0em rgba(225, 103, 16, 0.2), 2.5em 0em 0 0em rgba(225, 103, 16, 0.2), 1.75em 1.75em 0 0em rgba(225, 103, 16, 0.2), 0em 2.5em 0 0em rgba(225, 103, 16, 0.2), -1.8em 1.8em 0 0em rgba(225, 103, 16, 0.5), -2.6em 0em 0 0em rgba(225, 103, 16, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
  }
}
.tg-button {
  font-family: $font-open-sans;
  padding: 13px 15px;
  background-color: white;
  border-radius: 4px;
  font-size: 24px;
  color: #284E9E;
  font-weight: 600;
  letter-spacing: -.5px;
  line-height: 28px;
  box-shadow: 4px 4px 12px 1px rgba(35, 31, 32, .25);
  display: block;
  border: 0;


  &:focus {
    @include focus;
  }
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background-color: #ccc;
    cursor: default;
  }
}
.tg-button.tg-button--white {
}
.tg-button.tg-button--red {
  background-color: $color-orange;
  color: white;

  &:disabled {
    background-color: #ccc;
    cursor: default;
  }
}
.tg-button.tg-button--map-it {
  color: $color-orange;
}
.tg-button.tg-button--two-buttons {
  @include text-map-toggle;
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 12px;
  color: $color-orange;

  &:focus {
    box-shadow: none !important;
  }
}
.tg-button.tg-button--two-buttons.tg-button--two-buttons--is-active {
  background-color: $color-orange;
  color: white;

}
.tg-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  @include text-input;
  border: 1px solid #B2B5B8;
  caret-color: $color-orange;
  background-color: white;
  border-radius: 4px;
  padding: 17px;
  margin:0;

  &:focus {
    @include focus;
    border-color: $color-orange;
    box-shadow: 0 0 0 1px $color-orange !important;
    outline: none;
  }
}
.tg-input.tg-input--checkbox {
  appearance: checkbox;
  padding: 0;
}
.tg-input.tg-input--error {
  background-color: #FFF0F0;
  border: 1px solid $color-error;
  box-shadow: 0 0 0 1px $color-error;
  background-image: url(assets/icon-error.svg);
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 50px;
}
.tg-input.tg-input--select {
  background: transparent;
  background-color: white;
  background-image: url(assets/icon-caret-down.svg);
  background-repeat: no-repeat;
  background-position: right 12px center;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  option {
    text-shadow: none;

    &:not(:checked) {
      color: #231F20;
    }
  }
}
.tg-label {
  @include text-label;
}
.react-datepicker-wrapper{
  width:100%;
}

@media screen and (min-width: 769px) {
  .page__structure-body {
    flex: 1 1 1px;
    overflow: hidden;
  }
  .page__structure-container {
    overflow: hidden;
    max-height: 100%;
  }
  .cols.cols--two-buttons {
    position: absolute;
    top: 0;
    background: white;
    left: 50%;
    transform: translateX(-50%);
  }
}
