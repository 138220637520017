@import "../../utils/common";


.trip-plan {
  height: 100%;
  background-color: white;
}
.trip-plan__content {
  padding: 22px;
}
.trip-plan__form-error {
  @include text-input-error;
  color: $color-error;
  margin-bottom: 18px;
}
.trip-plan__form-waiting {
  @include text-trip-stat-header;
  color: gray;
  margin: 22px 0;
}
.trip-plan__input {
  width: 100%;
}
.trip-plan__plan-it {
  margin: 12px 0;
  min-width: 220px;
}
.trip-plan__title {
  @include text-page-title;
  margin-bottom: 24px;
}
.trip-plan__typeahead-parent {
  position: relative;
}
