@import "../../utils/common";


.rate-intro {
  height: 100%;
  background-color: #344996;
  background-image: url(../../assets/bg-illustration.svg);
  background-position: right -37px top 150px;
  background-repeat: no-repeat;
  background-size: 390px auto;
  min-height:640px;
}
.rate-intro__content {
  padding: 22px;
}
.rate-intro__text {
  @include text-intro;
  color: white;
}
.rate-intro__button {
  font-family: $font-open-sans;
  padding: 13px 15px;
  background-color: white;
  border-radius: 4px;
  font-size: 24px;
  color: #284E9E;
  font-weight: 600;
  letter-spacing: -.5px;
  line-height: 28px;
  margin: 25px auto;
  box-shadow: 4px 4px 12px 1px rgba(35, 31, 32, .25);
  display: block;
}
.rate-intro__rate {
  margin: 25px auto;
}

@media screen and (min-width: 475px) {
  .rate-intro {
    background-position: right -60px top 124px;
    background-repeat: no-repeat;
    background-size: 410px auto;
  }
}


@media screen and (min-width: 769px) {
  .rate-intro {
    background-position: right -60px top 154px;
    background-repeat: no-repeat;
    background-size: 410px auto;
    min-height:0;
  }
}
