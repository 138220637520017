@import "../../utils/common";


.page-ng-data {
}
.page-ng-data__content {
  padding: 60px 24px 24px;
  max-width: 510px;
  margin: 0 auto;
}
.page-ng-data__error {
  @include text-input-error;
  color: $color-error;
}
.page-ng-data__title {
  @include text-page-title;
  margin-bottom: 24px;
}
.tg-input.page-ng-data__input {
  width: 100%;
}


@media screen and (min-width: 769px) {
}
