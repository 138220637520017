.page-start {

}
.page-start.page-start--mobile-map {
  .footer {
    display: none;
  }
  .page__structure-body{
    overflow: auto;
  }
  .page-start__structure-left {
    display: none;
  }
  .page-start__structure-right {
    width: 100%;
    display: block;
    position: relative;
  }
  //.page__structure-body {
  //  height: 100%;
  //}
}
.page-start__map-close {
  display: none;
}
.page-start__structure-columns {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.page-start__structure-left {
  flex: auto;
}
.page-start__structure-right {
  display: none;
}

@media screen and (min-width: 769px) {
  .page-start__structure-left {
    flex: 0 1 400px;
    overflow: auto;
  }
  .page-start__structure-right {
    display: block;
    flex: auto;
  }
}
