@import "../../utils/common";


@-webkit-keyframes display-none-transition {
  0% {
    opacity: 0;
  }
}
@keyframes display-none-transition {
  0% {
    opacity: 0;
  }
}

.directions {
  width: 100%;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1.1;
  font-family: $font-open-sans;
  font-weight: 300;
}
.directions-duration {
  margin: .5em 0 1.5em;
}
.directions__legs {
  position: relative;
}
.directions__legs-detail {
  float: left;
  width: auto;
  margin-left: 130px;
  min-height: 40px;

  strong {
    font-weight: bold;
  }
}
.directions__legs-detail-steps {
}
.directions__legs-detail-steps-toggle {
  display: block;
  padding: 5px 0 0;
  text-decoration: none;
  color: #105EDA;
  vertical-align: middle;

  img {
    display: inline-block;
    line-height: 100%;
    vertical-align: middle;
  }
}
.directions__legs-detail-steps-list {
  padding: 5px 0;
  margin: 0;
  color: #666;
  font-size: 13px;
  display: none;
  opacity: 0;
}
.directions__legs-detail-steps-list.directions__legs-detail-steps-list--is-open {
  display: block;
  opacity: 1;
  -webkit-animation: display-none-transition .4s both;
  animation: display-none-transition .4s both;
}
.directions__legs-detail-steps-list-item {
  color: #666;
  font-size: 13px;
  padding: 5px 0;
  margin: 0;
  list-style: none;
}
.directions__legs-detail-steps-list-item-dot {
  position: absolute;
  z-index: 1;
  left: 89px;
  width: 17px;
  height: 17px;
  margin-top: -3px;
}
.directions__legs-detail-sub {
  font-size: 13px;
  padding-top: 5px;
  display: block;
}
.directions__legs-leg {
  overflow: hidden;
  z-index: 0;
  position: relative;
  padding-bottom: 20px;
  margin-top: -1px;
  min-height: 80px;
}
.directions__legs-leg.directions__legs-leg--first {
  .directions__legs-line.directions__legs-line--walk {
    //width: 6px;
    //height: auto !important;
    background-image: url(../../assets/icon-walk-a.svg);
  }
}
.directions__legs-leg.directions__legs-leg--last {
  .directions__legs-line.directions__legs-line--walk {
  }
}
.directions__legs-line {
  position: absolute;
  left: 94px;
  top: 3px;
  bottom: 0;
  width: 0;
  border-right: 6px solid #ccc;
}
.directions__legs-line.directions__legs-line--walk {
  height: auto !important;
  border-right: 0 !important;
  width: 6px;
  background-image: url(../../assets/icon-walk-b.svg);
}
.directions__legs-line.directions__legs-line--bus {
  border-color: #F58245;
}
.directions__legs-line-dot {
  content: '';
  display: block;
  position: absolute;
  top: -3px;
  left: -5px;
  width: 17px;
  height: 17px;
}
.directions__legs-mode {
  position: absolute;
  width: 60px;
}
.directions__legs-mode.directions__legs-mode--walk {
  .directions__legs-mode-stop-icon-container {
    margin-left: -8px;
    margin-right: -12px;
  }
}
.directions__legs-mode-stop {
  margin: 2px auto;
  text-align: center;
  display: block;
}
.directions__legs-mode-stop-icon-container {

}
.directions__legs-mode-stop-icon {
  display: -ms-flexbox;
  display: flex;
  height: 28px;
  vertical-align: middle;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.directions__legs-mode-stop-icon-image {
}
.directions__legs-mode-stop-text {
  font-weight: 700;
  font-size: 12px;
  padding: 2px;
  line-height: 18px;
}
.directions__legs-time {

}
.directions__legs-end-icon {
  position: absolute;
  z-index: 2;
  transform: translate(83px, -7px);

  img {
    width: 28px;
    height: auto;
  }
}
.directions__legs-start-icon {
  position: absolute;
  z-index: 2;
  transform: translate(83px, -7px);

  img {
    width: 28px;
    height: auto;
  }
}

@media screen and (min-width: 769px) {
  .directions {
    padding: 25px 24px 0;
  }
}
