@import "../../utils/common";


.page-thank-you {
}
.page-thank-you__content {
  padding: 60px 24px 24px;
}
.page-thank-you__rate-another {
}
.page-thank-you__sub-title {
  @include text-intro;
  margin: 25px 0 45px;
}
.page-thank-you__title {
  @include text-page-title;
  margin: 0 0 25px;
}


@media screen and (min-width: 769px) {
}
