@import "../../utils/common";


.survey {
  height: 100%;
}
.survey-iframe {
  height: 100%;
  max-width: 432px;
  margin: 0 auto;
  display: block;
}
