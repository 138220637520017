@import "../../utils/common";


@-webkit-keyframes display-none-transition {
  0% {
    opacity: 0;
  }
}
@keyframes display-none-transition {
  0% {
    opacity: 0;
  }
}

.map {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.map.map--mobile-map {
  .map__close {
    display: flex;
  }
  .map__react-map-container {
    border-bottom: 1px solid #ACB1C3;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    transition: .3s flex-basis;
    min-height: 150px;
  }
  .map__info {
    overflow: auto;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    height: auto;
    transition: .3s flex-basis;
    min-height: 100px;
  }
}
.map.map--mobile-map.map--mobile-map-focus-map {
  .map__react-map-container {
    flex-basis: 460px;
  }
  .map__info {
    flex-basis: 0;
  }
}
.map.map--mobile-map.map--mobile-map-focus-directions {
  .map__react-map-container {
    flex-basis: 0;
  }
  .map__info {
    flex-basis: 460px;
  }
}
.map__close {
  position: absolute;
  top: -8px;
  right: 8px;
  width: 60px;
  height: 60px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: white;
  box-shadow: 4px 4px 12px 1px rgba(35, 31, 32, .25);
}
.map__react-map-container {
  height: 100%;
  min-height: 180px;

  > div {
    width: 100% !important;
    height: 100% !important;
  }
}
.mapboxgl-marker {
}
.mapboxgl-marker.mapboxgl-marker--start,
.mapboxgl-marker.mapboxgl-marker--end {
  .marker-icon--dot {
    background-color: white;
    border: 2px solid black;
  }
}
.marker-icon {
}
.marker-icon.marker-icon--pin {
  width: 36px;
  height: 53px;
  transform: translate(-18px, -65px);
}
.marker-icon--dot {
  width: 14px;
  height: 14px;
  border-radius: 100px;
  transform: translate(-50%, -50%);
  background-color: black;
  border: 2px solid white;
}
.popup {
  display: none;
  opacity: 0;
}
.popup.popup--show {
  display: flex;
  opacity: 1;
  -webkit-animation: display-none-transition .2s both;
  animation: display-none-transition .2s both;
}
.popup-text {
  font-family: $font-open-sans;
  font-size: 16px;
  font-weight: 700;
}


@media screen and (min-width: 769px) {
  .map__info {
    display: none;
  }
  .page-start__structure-right {
    position: relative;
  }
}
