@import "../../utils/common";


.trip-result {
  height: 100%;
  overflow: auto;
  background-color: white;
}
.trip-result__calculating {
  margin-top: 50px;
  text-align: center;
}
.trip-result__calculating-spinner {
  margin-bottom: 10px;
}
.trip-result__calculating-text {
  @include text-body;
  color: $color-orange;
}
.trip-result__col-divider {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: -1px;
    top: 50%;
    height: 18px;
    border-right: 1px solid #231F20;
    transform: translateY(-50%);
  }
}
.trip-result__col-vs {
  position: relative;

  &:after {
    content: "vs";
    display: block;
    position: absolute;
    right: -7px;
    top: 50%;
    font-family: $font-open-sans;
    font-size: 15px;
    font-weight: 600;
    color: #231F20;
    line-height: 18px;
    transform: translateY(-50%);
  }
}
.trip-result__comparison {
  padding-top: 26px;
  padding-top:16px;
}
.trip-result__comparison.trip-result__comparison--current-only,
.trip-result__comparison.trip-result__comparison--nextgen-only {
  .trip-result__comparison-bg {
    flex: 0 0 100%;
  }
  .trip-result__comparison-totals {
    //width: 48%;

    .cols__col.cols__col--half {
      flex: 0 0 100%;
    }
  }
  .trip-result__comparison-details {
    //width: 48%;

    .cols__col.cols__col--half {
      flex: 0 0 100%;
    }
  }
  .trip-result__col-vs:after {
    display: none;
  }
  .trip-result__col-divider:after {
    display: none;
  }
}
.trip-result__comparison.trip-result__comparison--current-only {
}
.trip-result__comparison.trip-result__comparison--nextgen-only {
}
.trip-result__comparison-bgs {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
}
.trip-result__comparison-bg {
  flex: 0 0 50%;
}
.trip-result__comparison-bg.trip-result__comparison-bg--current {
  background-color: #6D6E71;
}
.trip-result__comparison-bg.trip-result__comparison-bg--nextgen {
  background-color: $color-orange;
}
.trip-result__comparison-content {
  position: relative;
  z-index: 1;
  padding: 13px 16px 32px;
}
.trip-result__comparison-titles {
  margin-bottom: 13px;
}
.trip-result__comparison-titles-title {
  @include text-sub-title;
  text-align: center;
  color: white;
}
.trip-result__comparison-totals {
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 8px;
}
.trip-result__comparison-totals-title {
  @include text-trip-stat-header-large;
  text-align: center;
}
.trip-result__comparison-totals-time {
  @include text-trip-stat-value-large;
  color: #6D6E71;
  text-align: center;
}
.trip-result__comparison-totals-unit {
  @include text-body;
  color: #6D6E71;
  text-align: center;
  margin-top: -15px;
}
.trip-result__comparison-details {
  padding: 5px;
  background-color: white;
  border-radius: 10px;
}
.trip-result__comparison-details-title {
  @include text-trip-stat-header;
  text-align: center;
  margin: 18px 0 3px;
}
.trip-result__comparison-details-info {
  margin-bottom: 18px;
}
.trip-result__comparison-details-time {
  @include text-trip-stat-value;
  color: #6D6E71;
  text-align: center;

  .trip-result__comparison-details-time-unit {
    @include text-body;
    color: #6D6E71;
    top: -20px;
    left: 3px;
    position: relative;
  }
}
.trip-result__comparison-missing {
}
.trip-result__comparison-missing-text {
  @include text-trip-stat-header;
  margin: 22px;
}
.trip-result__error {
  background-image: url(../../assets/icon-error.svg);
  background-repeat: no-repeat;
  background-position: left 24px center;
  padding:10px 22px 10px 57px;
}
.trip-result__error.trip-result__error--no-title {

}
  .trip-result__error-body {
  @include text-body;
  line-height: 22px;
}
.trip-result__error-title {
  @include text-sub-title;
  color: $color-error;
}
.trip-result__map-it {
  position: relative;
  margin: -15px auto 0;
  min-width: 220px;
}
.trip-result__query {
  z-index: 10;
  padding: 4px 22px;
  background-color: white;
}
.trip-result__rate-it {
  padding: 34px 24px;
}
.trip-result__rate-it-group {
  display: flex;
  margin: 25px -15px 30px;
  justify-content: center;
}
.trip-result__rate-it-label {
  width: 48px;
  height: 48px;
  color: transparent;
  font-size: 0;
  display: inline-block;
  border-radius: 100px;
  box-shadow: inset 0 0 1px 1px white;
  margin: 0 10px;
  cursor: pointer;
}
.trip-result__rate-it-radio {
  width: 100%;
  height: 100%;
  display: none;

  &:checked + label {
    background-color: $color-orange;

    img {
      filter: brightness(1000);
    }
  }
}
.trip-result__rate-it-title {
  @include text-sub-title;
  color: #231F20;
}
.trip-result__stats {
  position: relative;
  margin-top:10px;
}
.trip-result__title {
  @include text-page-title;
  padding: 22px 22px 18px;
}
.trip-result__waiting {
  @include text-trip-stat-header;
  color: gray;
  margin: 22px;
}

.tg-button.trip-result__rate-it-button {
  margin: 12px auto;
  min-width: 220px;
}


@media screen and (min-width: 769px) {
  .trip-result__map-it {
    display: none !important;
  }
  .trip-result__rate-it {
    padding-top: 0;
  }
}
