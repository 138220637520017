@import "../../utils/common";

.typeahead-results {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background-color: white;
  border: 2px solid #62b57f;
  margin-top: -5px;
  margin-left: 4px;
  box-shadow: 0 3px 3px rgba(0,0,0,.2);
  border-radius: 0 0 5px 5px;
  min-width: 90px;
  max-height: 344px;
  overflow-y: auto;
  z-index: 1;
}
.typeahead-results-result {
  display: block;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  line-height: 1;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  font-family: $font-open-sans;
  font-size:14px;
  font-weight: 300;

  &:hover{
    background-color:#ff0;
  }
}
.typeahead-results-result.typeahead-results-result--key-selected{
  background-color:#ff0;
}
