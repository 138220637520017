@import "../../utils/common";


.typeahead-input {
}
.typeahead-input.typeahead-input--in-progress {
  position: relative;

  input {
    background-image: none;
    padding-right:50px;
  }
  .typeahead-input__spinner {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 55px;
    //background-color: white;
    //border: 1px solid $color-orange;
    //border-left: none;
    //border-radius: 4px;
  }
  .tg-spinner.typeahead-input__spinner-image {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.typeahead-input__spinner {
  display: none;
}
