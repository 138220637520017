@import "../../utils/common";


.footer {
  background-color: #262626;
}
.footer__content {
  display: flex;
  padding: 22px;
  background-color: #E6E7E8;
  box-shadow: 0 1px 0 0 #ACB1C3;
}
.footer__links {

}
.footer__links-item {
  &:first-child {
    margin-bottom: 12px;
  }
}
.footer__links-link {
  @include text-footer;
  display: inline-block;
}
