$color-error: #FC1921;
$color-orange: #E16710;
$font-open-sans: "Open Sans", Helvetica, Arial, sans-serif;

@mixin reset_list() {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

@mixin text-body() {
  font-family: $font-open-sans;
  font-size: 15px;
  font-weight: 600;
  color: #231F20;
  line-height: 18px;
}
@mixin text-footer() {
  font-family: $font-open-sans;
  font-size: 13px;
  font-weight: 600;
  color: black;
  line-height: 16px;
}
@mixin text-input() {
  font-family: $font-open-sans;
  font-size: 18px;
  font-weight: 600;
  color: #231F20;
  line-height: 24px;
}
@mixin text-input-error(){
  font-family:$font-open-sans;
  font-size:15px;
  font-weight:600;
  line-height:18px;
}
@mixin text-intro() {
  font-family: $font-open-sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  color: #231F20;
}
@mixin text-label() {
  font-family: $font-open-sans;
  font-size: 14px;
  font-weight: 600;
  color: #808285;
  line-height: 18px;
  text-transform: uppercase;
}
@mixin text-map-toggle {
  font-family: $font-open-sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
@mixin text-page-title() {
  font-family: $font-open-sans;
  font-size: 28px;
  font-weight: 600;
  color: #231F20;
  letter-spacing: -.25px;
  line-height: 35px;
}
@mixin text-sub-title {
  font-family: $font-open-sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}
@mixin text-trip-stat-header-large {
  font-family: $font-open-sans;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -.75px;
  line-height: 40px;
  color: #231F20;
}
@mixin text-trip-stat-value-large {
  font-family: $font-open-sans;
  font-size: 64px;
  font-weight: 600;
  letter-spacing: -.75px;
  line-height: 87px;
  color: #231F20;
}
@mixin text-trip-stat-header {
  font-family: $font-open-sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: #231F20;
}
@mixin text-trip-stat-value {
  font-family: $font-open-sans;
  font-size: 48px;
  letter-spacing: -.5px;
  line-height: 65px;
  color: #231F20;
}

@mixin focus {
  box-shadow: 0 0 2px 2.5px $color-orange !important;
  outline: none;
}
