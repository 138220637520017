@import "../../utils/common";


.header {
  background-color: #262626;
}
.header__content {
  display: flex;
  padding: 22px;
}
.header__logo {
  flex: auto;
  display: flex;
  align-items: center;
}
.header__logo-image {
  height: 29px;
  width: auto;
}
.header__name {
  flex: none;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.header__name-text {
  font-family: $font-open-sans;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: white;
}
